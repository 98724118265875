import React, { FC, useContext, useEffect } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Controller, useFormContext } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { useHistory } from 'react-router-dom';
// import { MAX_DOCUMENT_LENGTH } from '../../constants/common'; // deactivated Attest
import { useTranslation } from '../../hooks/useTranslation';
import { MainLocales } from '../../locales/types';
import { BookingStep } from '../../routes/BookingCalendlyRedirectPage/enums/booking-step.enum';
import { BookingCalendlyRedirectInputsSchema } from '../../routes/BookingCalendlyRedirectPage/validation';
import { HomePageDialog } from '../../routes/HomePage/HomePageDialog/HomePageDialog';
import { store } from '../../store/store';
import { Cost, Patient, TiranCostPayload } from '../../types/api-types';
// import { DocumentType } from '../../types/enums/document-type.enum'; // deactivated Attest
import { HomePageDialogState } from '../../types/enums/home-page-dialog-state.enum';
import { Path } from '../../types/enums/path.enum';
import { UserVerificationState } from '../../types/enums/user-verification-state.enum';
import { getUserVerificationState } from '../../utils/auth';
import { hideHomePageDialog, showHomePageDialog } from '../../utils/home-page-dialog-state-handlers';
import { Price } from '../Price/Price';
import { Button } from '../ui/Button/Button';
import { Checkbox } from '../ui/Checkbox/Checkbox';
import { Input } from '../ui/Input/Input';
import style from './BookingOptionalExtras.module.scss';

interface Props {
  costs: Cost;
  patient?: Patient;
  preSelection?: boolean;
  setBookingStep: (step: BookingStep) => void;
  visible?: boolean;
}

type CostsObj = Omit<TiranCostPayload, 'sameDayService' | 'englishResults' | 'invoice'>;

function getSmallestPositiveValue(obj: CostsObj): number {
  const positiveValues = Object.values(obj).filter(value => value > 0);

  if (positiveValues.length === 0) {
    return 0; // No positive values found
  }

  // Find the minimum positive value
  const smallestPositiveValue = Math.min(...positiveValues);

  return smallestPositiveValue;
}

export const BookingOptionalExtras: FC<Props> = ({ costs, patient, preSelection, visible }) => {
  const { state, dispatch } = useContext(store);
  const { push } = useHistory();
  const { t } = useTranslation<MainLocales>('mainLocales');
  const {
    control,
    errors,
    formState,
    setValue,
    getValues,
    watch
  } = useFormContext<BookingCalendlyRedirectInputsSchema>();
  const watchCovidABTest = watch('covidABTest');
  // const watchCovidIgMTest = watch('covidIgMTest'); // Additional test removed
  const watchCovidPCRTest = watch('covidPCRTest');
  // const watchEnglishResults = watch('englishResults'); // Attest deactivated
  // const watchSameDayService = watch('sameDayService'); // PCR is now always same-day service
  const watchO_DROG = watch('O_DROG');
  const watchO_CDT = watch('O_CDT');
  const watchO_CDTPRO = watch('O_CDTPRO');
  const watchO_HIV = watch('O_HIV');
  const watchO_HEPC = watch('O_HEPC');
  const watchO_DAO = watch('O_DAO');
  const watchO_OMEGA = watch('O_OMEGA');
  const watchO_FSMEIGG = watch('O_FSMEIGG');
  const watchO_HAVAK = watch('O_HAVAK');
  const watchO_HBSAK = watch('O_HBSAK');
  const watchO_MASERN = watch('O_MASERN');
  const watchO_MUMPS = watch('O_MUMPS');
  const watchO_ROET = watch('O_ROET');
  const watchO_VZV = watch('O_VZV');
  const watchO_VITA = watch('O_VITA');
  const watchO_VITB1 = watch('O_VITB1');
  const watchO_VITB2 = watch('O_VITB2');
  const watchO_VITB3 = watch('O_VITB3');
  const watchO_VB6 = watch('O_VB6');
  const watchO_FOL = watch('O_FOL');
  const watchO_HOLOTC = watch('O_HOLOTC');
  const watchO_VB12 = watch('O_VB12');
  const watchO_VITC = watch('O_VITC');
  const watchO_VITD = watch('O_VITD');
  const watchO_VD2 = watch('O_VD2');
  const watchO_VITE = watch('O_VITE');
  const watchO_VITK1 = watch('O_VITK1');
  const watchO_VITK2 = watch('O_VITK2');
  const watchO_UBI = watch('O_UBI');
  const watchO_NAV = watch('O_NAV');
  const watchO_KV = watch('O_KV');
  const watchO_CAV = watch('O_CAV');
  const watchO_MGV = watch('O_MGV');
  const watchO_FEV = watch('O_FEV');
  const watchO_SEV = watch('O_SEV');
  const watchO_ZNV = watch('O_ZNV');
  const watchO_CUV = watch('O_CUV');
  const watchO_MINK = watch('O_MINK');
  const watchO_MINM = watch('O_MINM');
  const watchO_MINVB = watch('O_MINVB');
  const watchInvoice = watch('invoice');
  // This must be in sessionStorage to persist past a Calendly redirect or a page refresh
  const firstOpening = window.sessionStorage.getItem('invPreSel') ? true : false;
  // @ts-ignore
  const costsPayload: TiranCostPayload = costs.payload;
  const { covidTest, ...restCosts } = costsPayload;
  const { sameDayService, englishResults, invoice, ...testCosts } = costsPayload;
  const totalPrice = Object.entries(restCosts).reduce(
    (prev, [key, value]) => (getValues()[key as keyof BookingCalendlyRedirectInputsSchema] ? prev + value : prev),
    0,
  );
  const minTestCost = getSmallestPositiveValue(testCosts);
  const watchBloodDraw = watchO_CDT ||
    watchO_CDTPRO ||
    watchO_HIV ||
    watchO_HEPC ||
    watchO_DAO ||
    watchO_OMEGA ||
    watchO_FSMEIGG ||
    watchO_HAVAK ||
    watchO_HBSAK ||
    watchO_MASERN ||
    watchO_MUMPS ||
    watchO_ROET ||
    watchO_VZV ||
    watchO_VITA ||
    watchO_VITB1 ||
    watchO_VITB2 ||
    watchO_VITB3 ||
    watchO_VB6 ||
    watchO_FOL ||
    watchO_HOLOTC ||
    watchO_VB12 ||
    watchO_VITC ||
    watchO_VITD ||
    watchO_VD2 ||
    watchO_VITE ||
    watchO_VITK1 ||
    watchO_VITK2 ||
    watchO_UBI ||
    watchO_NAV ||
    watchO_KV ||
    watchO_CAV ||
    watchO_MGV ||
    watchO_FEV ||
    watchO_SEV ||
    watchO_ZNV ||
    watchO_CUV ||
    watchO_MINK ||
    watchO_MINM ||
    watchO_MINVB;

  useEffect(() => {
    setValue('bloodDraw', watchBloodDraw);
  }, [setValue, watchBloodDraw]);

  // TODO consider moving the next two to BookingProductSelectionPageContainer
  const handleHomeDialogClose = () => hideHomePageDialog(dispatch);

  const handleAppointmentClick = () => {
    // TODO consider adding validation here
    if (state.currentUser) {
      switch (getUserVerificationState(state.currentUser!)) {
        case UserVerificationState.VERIFIED: {
          return push(Path.BOOKING);
        }
        case UserVerificationState.EMAIL_NOT_VERIFIED: {
          return showHomePageDialog(dispatch, HomePageDialogState.confirmationEmailSent);
        }
        case UserVerificationState.PHONE_NUMBER_NOT_VERIFIED: {
          if (state.currentUser?.attributes.phone_number) {
            return showHomePageDialog(dispatch, HomePageDialogState.otpConfirmation);
          }

          return showHomePageDialog(dispatch, HomePageDialogState.phoneConfirmation);
        }
      }
    } else {
      showHomePageDialog(dispatch, HomePageDialogState.loginEmailBookingFlow);
    }
  };

  // @ts-ignore
  const patientDetails = [
    'firstName',
    'lastName',
    'birthDate',
    'gender',
    'phoneNumber',
    'email',
    'sendResultsTo',
    'streetName',
    'houseNumber',
    'postalCode',
    'city',
    'country',
    'symptomFree'
  ];
  const formName = 'productOptions';

  useFormPersist(formName, { watch, setValue, exclude: patientDetails});
  const persistedValues = JSON.parse(window.sessionStorage.getItem(formName) || '{}');

  const items = [
    {
      uuid: 'Category-1',
      rank: 3,
      heading: t('bookingFormTest_1'),
      content: (
        <div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='covidPCRTest'
              control={control}
              error={errors.covidPCRTest ? { type: 'required' } : null}
              // Note this requires sameDayService checkbox to exist
              onChange={({ target }) => {
                setValue('covidPCRTest', target.checked, { shouldValidate: true });
                setValue('sameDayService', target.checked, { shouldValidate: true });
                // tslint:disable-next-line:no-console
                // console.log(getValues());
              }}
              className={style.marginBottom}
              defaultValue={persistedValues.covidPCRTest ? true : false}
            >
              <span className={style.header}>{t('bookingFormTest_1_1')}</span>

            </Checkbox>
            <Price value={costsPayload.covidPCRTest} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='covidABTest'
              control={control}
              error={errors.covidABTest ? { type: 'required' } : null}
              onChange={({ target }) => {
                setValue('covidABTest', target.checked, { shouldValidate: true });
              }}
              className={style.marginBottom}
              defaultValue={persistedValues.covidABTest ? true : false}
            >
              <span className={style.header}>{t('bookingFormTest_1_2')}</span>
            </Checkbox>
            <Price value={costsPayload.covidABTest} symbol={costs.currencySymbol} />
          </div>
        </div>
      ),
    },
    {
      uuid: 'Category-2',
      rank: 1,
      heading: t('bookingFormTest_2'),
      content: (
        <div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_DROG'
              control={control}
              error={errors.O_DROG ? { type: 'required' } : null}
              onChange={({ target }) => {
                setValue('O_DROG', target.checked, { shouldValidate: true });
              }}
              className={style.marginBottom}
              defaultValue={persistedValues.O_DROG ? true : false}>
              <span className={style.header}>{t('bookingFormTest_2_1')}</span>
            </Checkbox>
            <Price value={costsPayload.O_DROG} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_CDT'
              control={control}
              error={errors.O_CDT ? { type: 'required' } : null}
              onChange={({ target }) => {
                setValue('O_CDT', target.checked, { shouldValidate: true });
              }}
              className={style.marginBottom}
              defaultValue={persistedValues.O_CDT ? true : false}>
              <span className={style.header}>{t('bookingFormTest_2_2')}</span>
            </Checkbox>
            <Price value={costsPayload.O_CDT} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_CDTPRO'
              control={control}
              error={errors.O_CDTPRO ? { type: 'required' } : null}
              onChange={({ target }) => {
                setValue('O_CDTPRO', target.checked, { shouldValidate: true });
              }}
              className={style.marginBottom}
              defaultValue={persistedValues.O_CDTPRO ? true : false}>
              <span className={style.header}>{t('bookingFormTest_2_3')}</span>
            </Checkbox>
            <Price value={costsPayload.O_CDTPRO} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_HIV'
              control={control}
              error={errors.O_HIV ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_HIV', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_HIV ? true : false}>
              <span className={style.header}>{t('bookingFormTest_2_4')}</span>
            </Checkbox>
            <Price value={costsPayload.O_HIV} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_HEPC'
              control={control}
              error={errors.O_HEPC ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_HEPC', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_HEPC ? true : false}>
              <span className={style.header}>{t('bookingFormTest_2_5')}</span>
            </Checkbox>
            <Price value={costsPayload.O_HEPC} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_DAO'
              control={control}
              error={errors.O_DAO ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_DAO', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_DAO ? true : false}>
              <span className={style.header}>{t('bookingFormTest_2_6')}</span>
            </Checkbox>
            <Price value={costsPayload.O_DAO} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_OMEGA'
              control={control}
              error={errors.O_OMEGA ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_OMEGA', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_DAO ? true : false}>
              <span className={style.header}>{t('bookingFormTest_2_7')}</span>
            </Checkbox>
            <Price value={costsPayload.O_OMEGA} symbol={costs.currencySymbol} />
          </div>
        </div>
      ),
    },
    {
      uuid: 'Category-3',
      rank: 2,
      heading: t('bookingFormTest_3'),
      content: (
        <div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_FSMEIGG'
              control={control}
              error={errors.O_FSMEIGG ? { type: 'required' } : null}
              onChange={({ target }) => {
                setValue('O_FSMEIGG', target.checked, { shouldValidate: true });
              }}
              className={style.marginBottom}
              defaultValue={persistedValues.O_FSMEIGG ? true : false}>
              <span className={style.header}>{t('bookingFormTest_3_1')}</span>
            </Checkbox>
            <Price value={costsPayload.O_FSMEIGG} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_HAVAK'
              control={control}
              error={errors.O_HAVAK ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_HAVAK', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_HAVAK ? true : false}>
              <span className={style.header}>{t('bookingFormTest_3_2')}</span>
            </Checkbox>
            <Price value={costsPayload.O_HAVAK} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_HBSAK'
              control={control}
              error={errors.O_HBSAK ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_HBSAK', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_HBSAK ? true : false}>
              <span className={style.header}>{t('bookingFormTest_3_3')}</span>
            </Checkbox>
            <Price value={costsPayload.O_HBSAK} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_MASERN'
              control={control}
              error={errors.O_MASERN ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_MASERN', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_MASERN ? true : false}>
              <span className={style.header}>{t('bookingFormTest_3_4')}</span>
            </Checkbox>
            <Price value={costsPayload.O_MASERN} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_MUMPS'
              control={control}
              error={errors.O_MUMPS ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_MUMPS', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_MUMPS ? true : false}>
              <span className={style.header}>{t('bookingFormTest_3_5')}</span>
            </Checkbox>
            <Price value={costsPayload.O_MUMPS} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_ROET'
              control={control}
              error={errors.O_ROET ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_ROET', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_ROET ? true : false}>
              <span className={style.header}>{t('bookingFormTest_3_6')}</span>
            </Checkbox>
            <Price value={costsPayload.O_ROET} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_VZV'
              control={control}
              error={errors.O_VZV ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_VZV', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_VZV ? true : false}>
              <span className={style.header}>{t('bookingFormTest_3_7')}</span>
            </Checkbox>
            <Price value={costsPayload.O_VZV} symbol={costs.currencySymbol} />
          </div>
        </div>
      ),
    },
    {
      uuid: 'Category-4',
      rank: 4,
      heading: t('bookingFormTest_4'),
      content: (
        <div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_VITA'
              control={control}
              error={errors.O_VITA ? { type: 'required' } : null}
              onChange={({ target }) => {
                setValue('O_VITA', target.checked, { shouldValidate: true });
              }}
              className={style.marginBottom}
              defaultValue={persistedValues.O_VITA ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_1')}</span>
            </Checkbox>
            <Price value={costsPayload.O_VITA} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_VITB1'
              control={control}
              error={errors.O_VITB1 ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_VITB1', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_VITB1 ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_2')}</span>
            </Checkbox>
            <Price value={costsPayload.O_VITB1} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_VITB2'
              control={control}
              error={errors.O_VITB2 ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_VITB2', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_VITB2 ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_3')}</span>
            </Checkbox>
            <Price value={costsPayload.O_VITB2} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_VITB3'
              control={control}
              error={errors.O_VITB3 ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_VITB3', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_VITB3 ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_4')}</span>
            </Checkbox>
            <Price value={costsPayload.O_VITB3} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_VB6'
              control={control}
              error={errors.O_VB6 ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_VB6', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_VB6 ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_5')}</span>
            </Checkbox>
            <Price value={costsPayload.O_VB6} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_FOL'
              control={control}
              error={errors.O_FOL ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_FOL', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_FOL ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_6')}</span>
            </Checkbox>
            <Price value={costsPayload.O_FOL} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_HOLOTC'
              control={control}
              error={errors.O_HOLOTC ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_HOLOTC', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_HOLOTC ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_7')}</span>
            </Checkbox>
            <Price value={costsPayload.O_HOLOTC} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_VB12'
              control={control}
              error={errors.O_VB12 ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_VB12', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_VB12 ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_8')}</span>
            </Checkbox>
            <Price value={costsPayload.O_VB12} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_VITC'
              control={control}
              error={errors.O_VITC ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_VITC', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_VITC ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_9')}</span>
            </Checkbox>
            <Price value={costsPayload.O_VITC} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_VITD'
              control={control}
              error={errors.O_VITD ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_VITD', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_VITD ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_10')}</span>
            </Checkbox>
            <Price value={costsPayload.O_VITD} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_VD2'
              control={control}
              error={errors.O_VD2 ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_VD2', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_VD2 ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_11')}</span>
            </Checkbox>
            <Price value={costsPayload.O_VD2} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_VITE'
              control={control}
              error={errors.O_VITE ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_VITE', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_VITE ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_12')}</span>
            </Checkbox>
            <Price value={costsPayload.O_VITE} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_VITK1'
              control={control}
              error={errors.O_VITK1 ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_VITK1', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_VITK1 ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_13')}</span>
            </Checkbox>
            <Price value={costsPayload.O_VITK1} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_VITK2'
              control={control}
              error={errors.O_VITK2 ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_VITK2', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_VITK2 ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_14')}</span>
            </Checkbox>
            <Price value={costsPayload.O_VITK2} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_UBI'
              control={control}
              error={errors.O_UBI ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_UBI', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_UBI ? true : false}>
              <span className={style.header}>{t('bookingFormTest_4_15')}</span>
            </Checkbox>
            <Price value={costsPayload.O_UBI} symbol={costs.currencySymbol} />
          </div>
        </div>
      ),
    },
    {
      uuid: 'Category-5',
      rank: 5,
      heading: t('bookingFormTest_5'),
      content: (
        <div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_NAV'
              control={control}
              error={errors.O_NAV ? { type: 'required' } : null}
              onChange={({ target }) => {
                setValue('O_NAV', target.checked, { shouldValidate: true });
              }}
              className={style.marginBottom}
              defaultValue={persistedValues.O_NAV ? true : false}>
              <span className={style.header}>{t('bookingFormTest_5_1')}</span>
            </Checkbox>
            <Price value={costsPayload.O_NAV} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_KV'
              control={control}
              error={errors.O_KV ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_KV', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_KV ? true : false}>
              <span className={style.header}>{t('bookingFormTest_5_2')}</span>
            </Checkbox>
            <Price value={costsPayload.O_KV} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_CAV'
              control={control}
              error={errors.O_CAV ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_CAV', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_CAV ? true : false}>
              <span className={style.header}>{t('bookingFormTest_5_3')}</span>
            </Checkbox>
            <Price value={costsPayload.O_CAV} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_MGV'
              control={control}
              error={errors.O_MGV ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_MGV', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_MGV ? true : false}>
              <span className={style.header}>{t('bookingFormTest_5_4')}</span>
            </Checkbox>
            <Price value={costsPayload.O_MGV} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_FEV'
              control={control}
              error={errors.O_FEV ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_FEV', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_FEV ? true : false}>
              <span className={style.header}>{t('bookingFormTest_5_5')}</span>
            </Checkbox>
            <Price value={costsPayload.O_FEV} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_SEV'
              control={control}
              error={errors.O_SEV ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_SEV', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_SEV ? true : false}>
              <span className={style.header}>{t('bookingFormTest_5_6')}</span>
            </Checkbox>
            <Price value={costsPayload.O_SEV} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_ZNV'
              control={control}
              error={errors.O_ZNV ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_ZNV', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_ZNV ? true : false}>
              <span className={style.header}>{t('bookingFormTest_5_7')}</span>
            </Checkbox>
            <Price value={costsPayload.O_ZNV} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_CUV'
              control={control}
              error={errors.O_CUV ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_CUV', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_CUV ? true : false}>
              <span className={style.header}>{t('bookingFormTest_5_8')}</span>
            </Checkbox>
            <Price value={costsPayload.O_CUV} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_MINK'
              control={control}
              error={errors.O_MINK ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_MINK', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_MINK ? true : false}>
              <span className={style.header}>{t('bookingFormTest_5_9')}</span>
            </Checkbox>
            <Price value={costsPayload.O_MINK} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_MINM'
              control={control}
              error={errors.O_MINM ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_MINM', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_MINM ? true : false}>
              <span className={style.header}>{t('bookingFormTest_5_10')}</span>
            </Checkbox>
            <Price value={costsPayload.O_MINM} symbol={costs.currencySymbol} />
          </div>
          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='O_MINVB'
              control={control}
              error={errors.O_MINVB ? { type: 'required' } : null}
              onChange={({ target }) => setValue('O_MINVB', target.checked, { shouldValidate: true })}
              className={style.marginBottom}
              defaultValue={persistedValues.O_MINVB ? true : false}>
              <span className={style.header}>{t('bookingFormTest_5_11')}</span>
            </Checkbox>
            <Price value={costsPayload.O_MINVB} symbol={costs.currencySymbol} />
          </div>
        </div>
      ),
    },
  ];

  const rankedItems = items.slice().sort((a, b) => a.rank - b.rank);

  return (
    <div style={{ width: '100%', display: visible ? undefined : 'none' }}>
      <div className={style.row}>
        <div className={style.column}>
          {/*TODO consider putting back icon back into here together with conditional as with buttons*/}
          {/*<Icon alt='<-' type='ic-back' onClick={handleBackIconClick} marginBottom />*/}
          <h1>{t('bookingFormTestTypeHeader')}</h1>
          <p>{t('bookingFormTestTypeSubheader')}</p>
          <Accordion
            allowMultipleExpanded={true}
            allowZeroExpanded={true}
            preExpanded={['Category-2']}
          >
            {rankedItems.map(item => (
              <AccordionItem
                key={item.uuid}
                uuid={item.uuid}
              >
                <AccordionItemHeading>
                  <AccordionItemButton>{item.heading}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>{item.content}</AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
          <span className={style.subparagraph}><br/></span>
          <h2>{t('bookingFormOptionalExtrasHeader')}</h2>
          <p>{t('bookingFormOptionalExtrasSubheader')}</p>
          {/*REMOVE SAME DAY OPTION - NEEDS TO EXIST BUT BE HIDDEN TO WORK*/}
          <div style={{ display: 'none' }}>
              <>
                <Checkbox
                  name='sameDayService'
                  control={control}
                  error={errors.sameDayService ? { type: 'required' } : null}
                  onChange={({ target }) => {
                    setValue('doNothing', target.checked );
                    // tslint:disable-next-line:no-console
                    // console.log(getValues());
                  }}
                  className={style.marginBottom}
                  defaultValue={persistedValues.sameDayService ? true : false}
                >
                  <span className={style.header}>{t('bookingFormSameDayService')}</span>
                </Checkbox>
                {/*<Price value={costsPayload.sameDayService} symbol={costs.currencySymbol} sign />*/}
              </>
          </div>
          {/* Use Controller for hidden fields */}
          <div style={{ display: 'none' }}>
            <Controller
              name='bloodDraw'
              control={control}
              defaultValue={false}
            />
          </div>
          {/*<div className={style.rowSpaceBetween}>
              {watchCovidPCRTest && (
                <>
                  <Checkbox
                    name='englishResults'
                    control={control}
                    error={errors.englishResults ? { type: 'required' } : null}
                    onChange={({ target }) => {
                      setValue('englishResults', target.checked, { shouldValidate: true });
                    }}
                    className={style.marginBottom}
                    defaultValue={persistedValues.englishResults ? true : false}
                  >
                    <span className={style.header}>{t('bookingFormEnglishResults')}</span>
                  </Checkbox>
                  <Price value={costsPayload.englishResults} symbol={costs.currencySymbol} sign />
                </>
              )}
          </div>
          {watchCovidPCRTest && (<span className={style.subparagraph}>{t('bookingFormEnglishResults2')}</span>)}

          {watchEnglishResults && !preSelection && (
            <>
              <p className={style.marginTop}>{t('bookingFormEnglishResultsSub')}</p>
              <Input
                control={control}
                name={'documents.' + DocumentType.PASSPORT}
                label={t(DocumentType.PASSPORT)}
                className={style.marginBottom}
                error={errors.documents?.PASSPORT!}
                defaultValue={persistedValues.documents?.PASSPORT || patient?.documents?.PASSPORT || undefined}
                maxLength={MAX_DOCUMENT_LENGTH}
              />
              <Input
                control={control}
                name='nationality'
                label={t('nationality')}
                className={style.marginBottom}
                error={errors.nationality!}
                defaultValue={persistedValues.nationality || patient?.nationality || undefined }
              />
            </>
          )}*/}

          <div className={style.rowSpaceBetween}>
            <Checkbox
              name='invoice'
              control={control}
              error={errors.invoice ? { type: 'required' } : null}
              onChange={({ target }) => {
                setValue('invoice', target.checked, { shouldValidate: true });
                // Needs to be in sessionStorage to persist past Calendly redirect
                preSelection && window.sessionStorage.setItem('invPreSel', String(target.checked));
              }}
              className={style.marginBottom}
              defaultValue={persistedValues.invoice ? true : false}
            >
              <span className={style.header}>{t('bookingFormInvoiceExtrasHeader')}</span>
            </Checkbox>
            <Price value={costsPayload.invoice} symbol={costs.currencySymbol} sign />
          </div>
          {watchInvoice && !firstOpening && !preSelection && (
            <>
              <p className={style.marginTop}>{t('bookingFormInvoiceExtrasInfo')}</p>
              <Input
                control={control}
                name={'invoiceAddress.addressLine1'}
                label={t('addressLine1')}
                className={style.marginBottom}
                error={errors.invoiceAddress?.addressLine1!}
                defaultValue={persistedValues.invoiceAddress?.addressLine1 || patient?.invoiceAddress?.addressLine1 || undefined }
                required
              />
              <Input
                control={control}
                name={'invoiceAddress.addressLine2'}
                label={t('addressLine2')}
                className={style.marginBottom}
                error={errors.invoiceAddress?.addressLine2!}
                defaultValue={persistedValues.invoiceAddress?.addressLine2 || patient?.invoiceAddress?.addressLine2 || undefined }
                required
              />
              <Input
                control={control}
                name={'invoiceAddress.addressLine3'}
                label={t('addressLine3')}
                className={style.marginBottom}
                error={errors.invoiceAddress?.addressLine3!}
                defaultValue={persistedValues.invoiceAddress?.addressLine3 || patient?.invoiceAddress?.addressLine3 || undefined }
              />
              <Input
                control={control}
                name={'invoiceAddress.addressLine4'}
                label={t('addressLine4')}
                className={style.marginBottom}
                error={errors.invoiceAddress?.addressLine4!}
                defaultValue={persistedValues.invoiceAddress?.addressLine4 || patient?.invoiceAddress?.addressLine4 || undefined }
              />
              <Input
                control={control}
                name={'invoiceAddress.addressLine5'}
                label={t('addressLine5')}
                className={style.marginBottom}
                error={errors.invoiceAddress?.addressLine5!}
                defaultValue={persistedValues.invoiceAddress?.addressLine5 || patient?.invoiceAddress?.addressLine5 || undefined }
              />
            </>
          )}
        </div>
        <div className={style.vline} />
        <div className={style.column}>
          <h1 className={style.mobileTopSpace}>{t('bookingFormBillingInfoHeader')}</h1>
          <div className={style.rowSpaceBetween}>
            {watchO_DROG && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_2_1')}</span>
                <Price value={costsPayload.O_DROG} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_CDT && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_2_2')}</span>
                <Price value={costsPayload.O_CDT} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_CDTPRO && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_2_3')}</span>
                <Price value={costsPayload.O_CDTPRO} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_HIV && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_2_4')}</span>
                <Price value={costsPayload.O_HIV} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_HEPC && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_2_5')}</span>
                <Price value={costsPayload.O_HEPC} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_DAO && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_2_6')}</span>
                <Price value={costsPayload.O_DAO} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_OMEGA && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_2_7')}</span>
                <Price value={costsPayload.O_OMEGA} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_FSMEIGG && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_3_1')}</span>
                <Price value={costsPayload.O_FSMEIGG} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_HAVAK && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_3_2')}</span>
                <Price value={costsPayload.O_HAVAK} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_HBSAK && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_3_3')}</span>
                <Price value={costsPayload.O_HBSAK} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_MASERN && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_3_4')}</span>
                <Price value={costsPayload.O_MASERN} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_MUMPS && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_3_5')}</span>
                <Price value={costsPayload.O_MUMPS} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_ROET && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_3_6')}</span>
                <Price value={costsPayload.O_ROET} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_VZV && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_3_7')}</span>
                <Price value={costsPayload.O_VZV} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchCovidPCRTest && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_1_1')}</span>
                <Price value={costsPayload.covidPCRTest} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchCovidABTest && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_1_2')}</span>
                <Price value={costsPayload.covidABTest} symbol={costs.currencySymbol} />
              </p>
            )}
            {/*
            {watchCovidIgMTest && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest1_3')}</span>
                <Price value={costsPayload.covidIgMTest} symbol={costs.currencySymbol} />
              </p>
            )}
            */}
            {watchO_VITA && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_1')}</span>
                <Price value={costsPayload.O_VITA} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_VITB1 && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_2')}</span>
                <Price value={costsPayload.O_VITB1} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_VITB2 && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_3')}</span>
                <Price value={costsPayload.O_VITB2} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_VITB3 && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_4')}</span>
                <Price value={costsPayload.O_VITB3} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_VB6 && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_5')}</span>
                <Price value={costsPayload.O_VB6} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_FOL && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_6')}</span>
                <Price value={costsPayload.O_FOL} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_HOLOTC && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_7')}</span>
                <Price value={costsPayload.O_HOLOTC} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_VB12 && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_8')}</span>
                <Price value={costsPayload.O_VB12} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_VITC && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_9')}</span>
                <Price value={costsPayload.O_VITC} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_VITD && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_10')}</span>
                <Price value={costsPayload.O_VITD} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_VD2 && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_11')}</span>
                <Price value={costsPayload.O_VD2} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_VITE && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_12')}</span>
                <Price value={costsPayload.O_VITE} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_VITK1 && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_13')}</span>
                <Price value={costsPayload.O_VITK1} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_VITK2 && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_14')}</span>
                <Price value={costsPayload.O_VITK2} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_UBI && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_4_15')}</span>
                <Price value={costsPayload.O_UBI} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_NAV && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_5_1')}</span>
                <Price value={costsPayload.O_NAV} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_KV && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_5_2')}</span>
                <Price value={costsPayload.O_KV} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_CAV && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_5_3')}</span>
                <Price value={costsPayload.O_CAV} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_MGV && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_5_4')}</span>
                <Price value={costsPayload.O_MGV} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_FEV && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_5_5')}</span>
                <Price value={costsPayload.O_FEV} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_SEV && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_5_6')}</span>
                <Price value={costsPayload.O_SEV} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_ZNV && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_5_7')}</span>
                <Price value={costsPayload.O_ZNV} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_CUV && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_5_8')}</span>
                <Price value={costsPayload.O_CUV} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_MINK && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_5_9')}</span>
                <Price value={costsPayload.O_MINK} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_MINM && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_5_10')}</span>
                <Price value={costsPayload.O_MINM} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchO_MINVB && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormTest_5_11')}</span>
                <Price value={costsPayload.O_MINVB} symbol={costs.currencySymbol} />
              </p>
            )}
            {watchBloodDraw && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormBloodDraw')}</span>
                <Price value={costsPayload.bloodDraw} symbol={costs.currencySymbol} />
              </p>
            )}
            {/*
            {watchSameDayService && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormSameDayService')}</span>
                <Price value={costsPayload.sameDayService} symbol={costs.currencySymbol} />
              </p>
            )}
            */}
            {/*{watchEnglishResults && (
              <>
                <p className={style.rowSpaceBetween}>
                  <span>{t('bookingFormEnglishResults')}</span>
                  <Price value={costsPayload.englishResults} symbol={costs.currencySymbol} />
                </p>
              </>
            )}*/}
            {watchInvoice && (
              <p className={style.rowSpaceBetween}>
                <span>{t('bookingFormInvoiceExtrasHeader')}</span>
                <Price value={costsPayload.invoice} symbol={costs.currencySymbol} />
              </p>
            )}
            <div className={style.hline} />
            <p className={style.rowSpaceBetween}>
              <span className={style.totalPrice}>{t('bookingFormTotal')}</span>
              <Price value={totalPrice} className={style.totalPrice} symbol={costs.currencySymbol} />
            </p>
          </div>
          {preSelection && (
            <Button type='primary'
                    disabled={formState.isSubmitting || totalPrice + costs.discount < minTestCost }
                    style={{ marginTop: '3rem' }} htmlType='button'
                    onClick={handleAppointmentClick}
            >
              {t('bookingFormProceedToTimeslot')}
            </Button>
          )}
          {!preSelection && (
          <Button type='primary'
                  disabled={
                      formState.isSubmitting ||
                      !formState.isValid ||
                      totalPrice + costs.discount < minTestCost
                      }
                  style={{ marginTop: '3rem' }} htmlType='submit'
          >
            {t('bookingFormProceedToPayment')}
          </Button>
          )}
          <p className={style.secondaryLine}>{t('additionalLineOne')}</p>
          <p className={style.secondaryLine}>{t('additionalLineTwo')}</p>
        </div>
      </div>
      <HomePageDialog handleDialogClose={handleHomeDialogClose} showDialog={state.showHomePageDialog}/>
    </div>
  );
};
// TODO consider moving HomePageDialog to BookingProductSelectionPageContainer instead
